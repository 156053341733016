<template>
    <div style="display: flex; flex-direction: column; width: 100%">
        <Dialog v-model:visible="confirmationModal" modal :closable="false" :style="{ width: '30rem' }">
            <div style="
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 32px;
          width: 100%;
        ">
                <img style="width: 48px" src="/images/logo_only_storm_black_font.png" />
                <div class="body1" style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          ">
                    {{ this.$langGetValue('campaignCreatedSuccess1') }}
                    <a style="color: var(--color_feedback_positive)"><b>{{ this.campaignName }}</b></a>
                    {{ this.$langGetValue('campaignCreatedSuccess2') }}
                </div>
                <div style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 8px;
            width: 100%;
          ">
                    <button class="primaryButton" @click="navigateToCampaign" style="width: 100%">
                        {{ this.$langGetValue('goToCampaignPage') }}
                    </button>
                    <button class="secondaryButton" @click="reloadPage" style="width: 100%">
                        {{ this.$langGetValue('createNewCampaign') }}
                    </button>
                </div>
            </div>
        </Dialog>
        <div style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        margin: 24px 0px;
      ">
            <div class="h2">{{ this.$langGetValue('newCampaign') }}</div>
        </div>
        <div style="
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 32px;
        padding: 0px 0px 24px 0px;
        width: 100%;
      ">
            <div style="
          display: flex;
          align-items: start;
          gap: 32px;
          width: 100%;
          padding-top: 48px;
        ">
                <div style="width: 50%; padding-right: 32px">
                    <div style="display: flex; align-items: start; gap: 4px">
                        <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('campaignName') }}</div>
                        <i style="color: var(--color_feedback_negative); cursor: help"
                            :title="this.$langGetValue('fieldRequired')">*</i>
                    </div>
                    <div style="margin-bottom: 16px">
                        <span class="body3" style="color: var(--color_gray_medium)">{{
                            this.$langGetValue('chooseCampaignName') }}</span>
                    </div>
                    <InputText class="inputText" invalid
                        :class="{ 'invalid-input': (campaignName == null || campaignName.length <= 0) && clickedSubmit }"
                        v-model="campaignName" style="width: 100%" />
                </div>
            </div>
        </div>
        <div style="
        display: flex;
        align-items: center;
        justify-content: start;
        gap: 32px;
        padding: 0px 0px 24px 0px;
        width: 100%;
      ">
            <div style="
          display: flex;
          align-items: start;
          gap: 32px;
          width: 100%;
          padding-top: 48px;
        ">
                <div style="width: 100%">
                    <div style="display: flex; align-items: start; gap: 4px">
                        <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('rexBpmDeadline') }}</div>
                        <i style="color: var(--color_feedback_negative); cursor: help"
                            :title="this.$langGetValue('fieldRequired')">*</i>
                    </div>
                    <div style="margin-bottom: 16px">
                        <span class="body3" style="color: var(--color_gray_medium)">{{
                            this.$langGetValue('chooseRexBpmDeadline') }}</span>
                    </div>
                    <InputNumber :useGrouping="false" class="bpmDeadline" v-model="bpmDeadline" :min="1"
                        inputId="integeronly" style="width: 100%" suffix=" dia(s)" />
                </div>
            </div>
            <div style="
          display: flex;
          align-items: start;
          gap: 32px;
          width: 100%;
          padding-top: 48px;
        ">
                <div style="width: 100%">
                    <div style="display: flex; align-items: start; gap: 4px">
                        <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('roTeamDeadline') }}</div>
                        <i style="color: var(--color_feedback_negative); cursor: help"
                            :title="this.$langGetValue('fieldRequired')">*</i>
                    </div>
                    <div style="margin-bottom: 16px">
                        <span class="body3" style="color: var(--color_gray_medium)">{{
                            this.$langGetValue('chooseRoTeamDeadline') }}</span>
                    </div>
                    <InputNumber :useGrouping="false" class="roDeadline" v-model="roDeadline" :min="1" inputId="integeronly"
                        style="width: 100%" suffix=" dia(s)" />
                </div>
            </div>
        </div>
        <div style="gap: 32px">
            <div style="
          display: flex;
          align-items: start;
          gap: 32px;
          width: 100%;
          padding-top: 48px;
        ">
                <div style="width: 100%">
                    <div style="display: flex; align-items: start; gap: 4px">
                        <div class="h4" style="margin-bottom: 4px">{{ this.$langGetValue('areasProcesses') }}</div>
                        <i style="color: var(--color_feedback_negative); cursor: help"
                            :title="this.$langGetValue('fieldRequired')">*</i>
                    </div>
                    <div style="margin-bottom: 16px">
                        <span class="body3" style="color: var(--color_gray_medium)">{{
                            this.$langGetValue('chooseAreasProcesses') }}</span>
                    </div>
                    <div class="areasTree" style="padding: 0px 0px 0px 0px;">
                        <TreeTable class="customTreeTable" :paginator="true" :rows="10"
                            :rowsPerPageOptions="[10, 20, 50, this.nodesAreas.length].sort((a, b) => a - b)"
                            v-model:selectionKeys="selectedAreasKeys" :filters="filters" :value="nodesAreas"
                            @nodeSelect="onNodeSelect" @nodeUnselect="onNodeUnselect" selectionMode="checkbox">
                            <template #header style="background-color: white">
                                <div style="
                                display: flex;
                                align-items: center;
                                justify-content: start;
                                margin-left: 16px;
                                gap: 16px;
                                width: 100%;
                                ">
                                    <Checkbox v-model="selectAll" :binary="true" />
                                    <i class="pi pi-search" />
                                    <InputText class="inputText" v-model="filters['global']"
                                        :placeholder="this.$langGetValue('searchAreas')" style="width: 30%" />
                                </div>

                            </template>
                            <Column field="label" class="flex align-items-center" expander>
                                <template #body="slotProps">
                                    <div class="ml-1">
                                        <b class="area-name">{{ slotProps.node.data.label }}</b>
                                        <div class="mt-2"
                                            v-if="slotProps.node.data.bpm != undefined || slotProps.node.data.rex != undefined"
                                            style="display: flex; flex-direction: row; align-items: center; gap: 8px">
                                            <div class="body3" style="color: var(--color_gray_medium)">
                                                <b>BPM:</b> {{ slotProps.node.data.bpm }}
                                            </div>
                                            <div class="body3" style="color: var(--color_gray_medium)">
                                                <b>REX:</b> {{ slotProps.node.data.rex }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </Column>
                        </TreeTable>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div style="display: flex; flex-direction: column; padding: 16px 0px 0px 0px; width: 100%; justify-content: start; align-items: start;">
            <div class="body2" style="margin-bottom: 4px">Áreas selecionadas</div>
            <div class="flex-wrap" style="display: flex; flex-direction: row; gap: 8px; width: 100%;">
                <Chip class="primaryChip" :v-for="(area, index) in selectedAreasLabels" :key="index" :label="area"></Chip>
            </div>
        </div> -->
        <div style="
        display: flex;
        padding: 64px 0px 16px 0px;
        width: 100%;
        justify-content: center;
        align-items: center;
      ">
            <Button @click="handleNewCampaign" :label="this.$langGetValue('registerNewCampaign')" class="primaryButton" />
        </div>
    </div>
</template>

<script>
// import BackendService from "../service/BackendService";
import IncidentService from "../service/IncidentService.js";
import BiaService from "../service/BiaService.js";
import Util from "../service/Util";

export default {
    data() {
        return {
            processArray: [],
            bpmDeadline: null,
            roDeadline: null,
            campaignName: null,
            selectedAreasKeys: [],
            nodesAreas: [],
            allAreasList: [],
            areaHierarchy: IncidentService.incidentFields.hierarchy.data,
            returnedID: null,
            confirmationModal: false,
            clickedSubmit: false,
            filters: {},
            selectAll: false,
        };
    },
    computed: {
        inputLabels() {
            return [
                { label: this.$langGetValue('campaignName'), value: this.campaignName },
                { label: this.$langGetValue('rexBpmDeadline'), value: this.bpmDeadline },
                { label: this.$langGetValue('roTeamDeadline'), value: this.roDeadline },
                { label: this.$langGetValue('areasProcesses'), value: this.processArray },
            ];
        }
    },
    watch: {
        bpmDeadline(newValue) {
            if ((newValue === null || newValue <= 0) && this.buttonClicked === true) {
                this.updateBorderColorInvalid(".bpmDeadline .p-inputnumber-input");
            } else if (newValue > 0) {
                this.updateBorderColorValid(".bpmDeadline .p-inputnumber-input");
            }
        },
        roDeadline(newValue) {
            if ((newValue === null || newValue <= 0) && this.buttonClicked === true) {
                this.updateBorderColorInvalid(".roDeadline .p-inputnumber-input");
            } else if (newValue > 0) {
                this.updateBorderColorValid(".roDeadline .p-inputnumber-input");
            }
        },
        selectAll() {
            if (this.selectAll) {
                var areasName = []

                const areasNameClass = document.getElementsByClassName('area-name');

                for(let i=0; i<areasNameClass.length; i++){
                    areasName.push(areasNameClass[i].innerText)
                }
       
                var tempObject = {}

                this.nodesAreas.forEach((area) => {
                    if (areasName.includes(area.key)) {
                        tempObject[area.key] = { checked: true, partialChecked: false }

                        if ("children" in area) {
                            area.children.forEach((item) => {
                                tempObject[item.key] = { checked: true, partialChecked: false }
                            });
                        }

                        this.onNodeSelect(area)
                    }
                })

                setTimeout(() => {
                    this.selectedAreasKeys = tempObject
                }, 100)
            }
            else {
                this.selectedAreasKeys = []
                this.processArray = []
            }
        },
        filters() {
            console.log(this.filters)
        }
    },
    async mounted() {

        await this.populateHierarchyArray();

        await BiaService.getProcess().then((response) => {
            var tempList = [];
            response.data.forEach((item) => {
                var tempChildren = [];

                item.process.forEach((process) => {
                    tempChildren.push({
                        key: process.id,
                        data: { label: process.data.name, id: process.id, bpm: process.data.bpm, rex: process.data.rex },
                    });
                });


                tempList.push({
                    key: this.getAreaNameById(item.area),
                    data: { label: this.getAreaNameById(item.area) },
                    children: tempChildren,
                });
            });

            tempList = tempList.sort((a, b) => {
                if (a.key < b.key) {
                    return -1;
                }
                if (a.key > b.key) {
                    return 1;
                }
                return 0;
            });

            this.nodesAreas = tempList;
        });
    },
    methods: {
        updateBorderColorInvalid(selector) {
            var elements = document.querySelectorAll(selector);
            elements.forEach(function (element) {
                element.style.borderColor = "#EB3D47";
                element.style.borderWidth = "2px !important";
            });
        },

        updateBorderColorValid(selector) {
            var elements = document.querySelectorAll(selector);
            elements.forEach(function (element) {
                element.style.borderColor = "#195AB4";
            });
        },
        getAreaNameById(id) {
            var areaName = null;

            areaName = this.allAreasList.filter((area) => area.id == id);

            return areaName[0]?.label;
        },
        onNodeSelect(event) {
            if ("children" in event) {
                event.children.forEach((item) => {
                    this.processArray.push(item.data.id);
                });
            } else {
                this.processArray.push(event.data.id);
            }
        },
        onNodeUnselect(event) {
            if ("children" in event) {
                event.children.forEach((process) => {
                    this.processArray = this.processArray.filter(
                        (item) => item != process.data.id
                    );
                });
            } else {
                this.processArray = this.processArray.filter(
                    (item) => item != event.data.id
                );
            }
        },
        isFilled() {
            if (
                this.bpmDeadline != null &&
                this.roDeadline != null &&
                this.campaignName != null &&
                this.campaignName.length > 0 &&
                this.processArray.length > 0
            ) {
                return true;
            } else {
                return false;
            }
        },
        handleNewCampaign() {
            this.clickedSubmit = true;

            if (this.isFilled()) {

                Util.loading = true

                var payload = {
                    name: this.campaignName,
                    dateOfStart: new Date(),
                    bpmValidate: this.bpmDeadline,
                    roValidate: this.roDeadline,
                    process: this.processArray,
                };

                BiaService.createBia(payload).then((returnedID) => {
                    Util.loading = false

                    this.confirmationModal = true;
                    this.returnedID = returnedID;
                });
            } else {

                var notFilledInputs = null;

                this.inputLabels.forEach((item) => {
                    if (item.value == null) {
                        if (notFilledInputs == null) {
                            notFilledInputs = item.label;
                        } else {
                            notFilledInputs = notFilledInputs + ", " + item.label;
                        }
                    } else if (Array.isArray(item.value)) {
                        if (item.value.length <= 0) {
                            notFilledInputs = notFilledInputs + ", " + item.label;
                        }
                    } else if (typeof item.value === 'string') {
                        if (item.value.length <= 0) {
                            notFilledInputs = item.label;
                        }
                    }
                });

                if (this.roDeadline == null || this.roDeadline <= 0) {
                    this.updateBorderColorInvalid(".roDeadline .p-inputnumber-input");
                }
                if (this.bpmDeadline == null || this.bpmDeadline <= 0) {
                    this.updateBorderColorInvalid(".bpmDeadline .p-inputnumber-input");
                }
                if (this.processArray.length <= 0) {
                    this.updateBorderColorInvalid(".customTreeTable");
                }
                this.$toast.add({
                    severity: "error",
                    summary: this.$langGetValue('blankFields'),
                    detail: notFilledInputs,
                    life: 3000,
                });
            }
        },
        navigateToCampaign() {
            this.$router.push({
                name: "bia-campaign",
                query: { id: this.returnedID.data.id },
            });
        },
        reloadPage() {
            window.location.reload()
        },
        async populateHierarchyArray() {
            function visitNodes(tree, path = []) {
                if (!tree) {
                    return [];
                }

                path.push(tree.label);
                const info = {
                    id: tree.id,
                    label: tree.label,
                    path: path.join(" -> "),
                    selectable: tree.selectable,
                };

                const result = [info];

                if (tree.children && tree.children?.length > 0) {
                    for (const children of tree.children) {
                        result.push(...visitNodes(children, path.slice()));
                    }
                }

                return result;
            }

            for (let i = 0; i < this.areaHierarchy?.length; i++) {
                this.allAreasList = this.allAreasList.concat(
                    visitNodes(this.areaHierarchy[i])
                );
            }

            return true
        },
    },
};
</script>

<style>
.p-treetable .p-treetable-header {
    background-color: transparent !important;
    border-width: 0px 0px 1px 0px;
}

.customTreeTable .p-treetable-thead th {
    display: none !important;
}

.invalid-input {
    border-color: var(--color_feedback_negative);
}
</style>
